import React, { useMemo, useRef } from 'react'
import { Maybe } from 'graphql/jsutils/Maybe'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { slugs } from '@/constants'
import { WatchDepartments } from '@/organisms/WatchDepartments'
import { BackgroundPosition, WatchProjectHero, WatchProjectHeroNew } from '@/organisms/WatchProjectHero'
import { CatalogTitle } from '@/services/ContentCatalog'
import { Downloadable } from '@/services/DownloadablesService'
import { ShopifyStoreConfig } from '@/services/EarlyAccessService'
import { LightFranchise } from '@/services/FranchiseService'
import { useGuildMember } from '@/services/GuildUserService'
import { isSellingTickets } from '@/services/PhaseManagerService'
import {
  getIsGeoblocked,
  Project,
  ProjectType,
  Episode,
  EpisodeLinkMeta,
  SeasonLinkMeta,
} from '@/services/ProjectsService'
import { PageDataContext, PageModel } from '@/services/RenderService'
import { Story } from '@/services/StoriesService'
import { GetTheatricalPreReleasePageDataResult } from '@/services/TheatricalPreReleaseService'
import { useUser } from '@/services/UserService'
import { ProjectTheme, TheatricalRegion } from '@/types/codegen-contentful'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import {
  getContinueWatchingCta,
  getContinueWatchingCtaNew,
  getDefaultCta,
  getTrailerCta,
  isARecap,
  isASneakPeek,
} from '@/utils/EpisodeUtil'
import { useLocale } from '@/utils/LocaleUtil'
import { getProjectTheme } from '@/utils/project-themes'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import { AppPromoSection } from '@/views/LandingView/AppPromoSection'
import { useWatchProjectData } from '@/views/WatchProjectView/useWatchProjectData'
import { FranchiseFilmSeriesCTA } from './FranchiseFilmSeriesCTA'
import { WatchProjectContext } from './WatchProjectContext'
import { useFadeBackgroundOnScroll } from './useFadeBackgroundOnScroll'
import { useWatchProject } from './useWatchProject'

export interface WatchProjectViewProps {
  currentlyFunding?: boolean
  discoveryPosterLandscapeCloudinaryPath: string
  downloadables: Downloadable[]
  hasPayItForward: boolean
  id: string
  name: string
  projectType: ProjectType
  shopifyConfig?: ShopifyStoreConfig
  singleStory?: boolean
  slug: string
  story?: Story
  theaterBackgroundPosition?: BackgroundPosition
  theaterDescription: string
  theatricalRegion?: TheatricalRegion[]
  theatricalRelease?: TheatricalReleaseObject
  seasons?: SeasonLinkMeta[]
  pageDataContext: Maybe<PageDataContext>
  page: Maybe<PageModel>
  contentfulProjectTheme: Maybe<ProjectTheme>
  catalogTitle?: CatalogTitle
  pageData?: GetTheatricalPreReleasePageDataResult
  isProjectInEarlyAccess?: boolean
  projectData: Project
  guildScore?: number
  isGuildMember?: boolean
  isLoggedIn?: boolean
  franchise?: LightFranchise
  hasWatchableReasons?: boolean
}

export const WatchProjectView: React.FC<WatchProjectViewProps> = (props) => {
  const {
    discoveryPosterLandscapeCloudinaryPath,
    downloadables,
    hasPayItForward,
    id,
    name,
    projectType,
    singleStory,
    story,
    slug,
    theaterBackgroundPosition,
    theaterDescription,
    theatricalRegion,
    theatricalRelease,
    seasons = [],
    page,
    pageData,
    pageDataContext,
    contentfulProjectTheme,
    catalogTitle,
    isProjectInEarlyAccess = false,
    projectData,
    shopifyConfig,
    guildScore,
    franchise,
    hasWatchableReasons = false,
  } = props
  const backgroundRef = useRef<HTMLDivElement>(null)
  const boundaryRef = useRef<HTMLDivElement>(null)
  useFadeBackgroundOnScroll({ backgroundRef, boundaryRef })
  const { isGuildMember: isGuildMemberFromProps } = props
  const { isGuildMember: isGuildMemberFromHook } = useGuildMember()
  const { isLoggedIn: isLoggedInFromProps } = props
  const { isLoggedIn: isLoggedInFromHook } = useUser()
  // Some Watch Project Pages are statically generated, so we need to check if the user is a guild member here when not provided server-side
  const isGuildMember = isGuildMemberFromProps !== undefined ? isGuildMemberFromProps : isGuildMemberFromHook
  const numSeasons = projectData?.seasons?.length
  const { project } = useWatchProject({ projectSlug: slug, skip: numSeasons === 0, isGuildMember })
  const isGeoblockedProject = getIsGeoblocked(project ?? projectData)

  const isLoggedIn = isLoggedInFromProps !== undefined ? isLoggedInFromProps : isLoggedInFromHook
  const canShowTickets = isSellingTickets(projectData?.primaryFlowPhases ?? [])
  const watchableReasons = useMemo(
    () => projectData?.title?.watchableReasons ?? [],
    [projectData?.title?.watchableReasons],
  )

  const canShowGuildHero = Boolean(
    !canShowTickets &&
      isProjectInEarlyAccess &&
      (!isLoggedIn ||
        (isLoggedIn &&
          !isGuildMember &&
          (projectType === 'series' ||
            (!watchableReasons && projectType === 'movie') ||
            (watchableReasons && watchableReasons?.length === 0 && projectType === 'movie')))),
  )

  const { t } = useTranslate('watch')
  const { locale } = useLocale()
  const { currentEpisode } = useWatchProjectData({
    data: projectData,
    skip: !seasons?.length,
    isGuildMember,
    isGeoblockedProject,
  })
  const projectTheme = getProjectTheme(slug) ?? {}
  const episodeTrailer = seasons[0]?.episodes?.find((episode) => episode.isTrailer)
  const showFranchiseFilmSeriesCTA = Boolean(projectData?.slug === slugs.homesteadSeries)

  const showHeroProps = useMemo(() => {
    if (projectData?.continueWatching?.guid && currentEpisode) {
      const resume = getResumeText(canShowGuildHero, t)
      return getResumeCta({ currentEpisode, locale, projectType, resume, slug, canShowGuildHero })
    } else if (isARecap(currentEpisode) && !canShowGuildHero && isGuildMember) {
      return getRecapCta({ currentEpisode, locale, slug, theaterDescription, t })
    } else if (isASneakPeek(currentEpisode)) {
      return getSneakPeekCta({ currentEpisode, locale, slug, theaterDescription, contentfulProjectTheme, t })
    } else if (currentEpisode?.isTrailer) {
      return getTrailerCtaProps({ currentEpisode, locale, slug, theaterDescription, t })
    } else if (
      !isGuildMember &&
      (!watchableReasons || (watchableReasons && watchableReasons?.length === 0)) &&
      isProjectInEarlyAccess &&
      projectData?.trailers &&
      projectData?.trailers?.length > 0
    ) {
      return getDefaultTrailerCta({ projectData, slug, theaterDescription, t })
    } else if (
      !isGuildMember &&
      (!watchableReasons || (watchableReasons && watchableReasons?.length === 0)) &&
      isProjectInEarlyAccess &&
      episodeTrailer
    ) {
      return getEarlyAccessCta({ slug, theaterDescription, episodeTrailer, t })
    } else {
      return getWatchNowCta({ currentEpisode, locale, slug, theaterDescription, t })
    }
  }, [
    projectData,
    currentEpisode,
    canShowGuildHero,
    isGuildMember,
    watchableReasons,
    isProjectInEarlyAccess,
    episodeTrailer,
    t,
    locale,
    projectType,
    slug,
    theaterDescription,
    contentfulProjectTheme,
  ])

  const shareCtaPath = getMovieEpisodeSharePath({
    projectType,
    projectData,
    slug,
    defaultCtaPath: showHeroProps.ctaPath ?? '',
  })

  return (
    <WatchProjectContext.Provider value={{ projectTheme, project: projectData }}>
      <div className="overflow-x-hidden bg-core-gray-950 pt-24 text-white">
        <main>
          <When condition={canShowGuildHero}>
            <PaddedContainer fluid className="min-h-[427px] md:min-h-[429px]">
              <WatchProjectHeroNew
                className="mb-12"
                cloudinaryImgPath={
                  discoveryPosterLandscapeCloudinaryPath
                    ? `${discoveryPosterLandscapeCloudinaryPath}.webp`
                    : '/v1706130971/angel-app/angel-studios/discovery_images/Theater-Home-Page-Banner_v2_2024-01-16_1920x1080.webp'
                }
                currentEpisode={currentEpisode}
                hasPayItForward={hasPayItForward}
                id={id}
                promotion={null}
                showTitle={name}
                slug={slug}
                theaterBackgroundPosition={theaterBackgroundPosition}
                theatricalRegion={theatricalRegion}
                theatricalRelease={theatricalRelease}
                isGuildMemberContent={isProjectInEarlyAccess}
                catalogTitle={catalogTitle}
                projectType={projectType}
                pageData={pageData}
                shopifyConfig={shopifyConfig}
                contentfulProjectTheme={contentfulProjectTheme}
                guildScore={guildScore}
                isGuildMember={isGuildMember}
                isGeoBlockedProject={isGeoblockedProject}
                shareCtaPath={shareCtaPath}
                {...showHeroProps}
              />
            </PaddedContainer>
          </When>
          <When condition={!canShowGuildHero}>
            <PaddedContainer fluid>
              <WatchProjectHero
                className="mb-12"
                cloudinaryImgPath={
                  discoveryPosterLandscapeCloudinaryPath
                    ? `${discoveryPosterLandscapeCloudinaryPath}.webp`
                    : '/v1706130971/angel-app/angel-studios/discovery_images/Theater-Home-Page-Banner_v2_2024-01-16_1920x1080.webp'
                }
                currentEpisode={currentEpisode}
                id={id}
                promotion={null}
                showTitle={name}
                slug={slug}
                theaterBackgroundPosition={theaterBackgroundPosition}
                theatricalRegion={theatricalRegion}
                theatricalRelease={theatricalRelease}
                isGuildMemberContent={isProjectInEarlyAccess}
                projectType={projectType}
                pageData={pageData}
                shopifyConfig={shopifyConfig}
                guildScore={guildScore}
                isGuildMember={isGuildMember}
                isGeoBlockedProject={isGeoblockedProject}
                isLoggedIn={isLoggedIn}
                hasWatchableReasons={hasWatchableReasons}
                shareCtaPath={shareCtaPath}
                {...showHeroProps}
              />
            </PaddedContainer>
          </When>
        </main>
        <div ref={boundaryRef}>
          <WatchDepartments
            projectData={projectData}
            loadingProjectData={false}
            downloadables={downloadables}
            slug={slug}
            singleStory={singleStory}
            story={story}
            page={page}
            pageDataContext={pageDataContext}
            contentfulProjectTheme={contentfulProjectTheme}
            isGuildMember={isGuildMember}
            franchise={franchise}
            hasWatchableReasons={hasWatchableReasons}
            isGeoblockedProject={isGeoblockedProject}
          />
        </div>
        <LazyHydrate whenVisible>
          <AppPromoSection />
        </LazyHydrate>
        {showFranchiseFilmSeriesCTA && isGuildMember && (
          <FranchiseFilmSeriesCTA project={projectData} franchise={franchise} />
        )}
      </div>
    </WatchProjectContext.Provider>
  )
}

const getResumeText = (canShowGuildHero: boolean, t: TranslateFunction) => {
  return canShowGuildHero ? t('continue', 'Continue') : t('resume', 'Resume')
}

const getResumeCta = ({
  currentEpisode,
  locale,
  projectType,
  resume,
  slug,
  canShowGuildHero,
}: {
  currentEpisode: Episode
  locale: string
  projectType: ProjectType
  resume: string
  slug: string
  canShowGuildHero: boolean
}) => {
  return canShowGuildHero
    ? getContinueWatchingCtaNew({ currentEpisode, locale, projectType, resume, slug })
    : getContinueWatchingCta({ currentEpisode, locale, projectType, resume, slug })
}

const getRecapCta = ({
  currentEpisode,
  locale,
  slug,
  theaterDescription,
  t,
}: {
  currentEpisode?: Episode
  locale: string
  slug: string
  theaterDescription: string
  t: TranslateFunction
}) => {
  const ctaText = t('watchRecap', 'Watch Recap')
  return getTrailerCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
}

const getSneakPeekCta = ({
  currentEpisode,
  locale,
  slug,
  theaterDescription,
  contentfulProjectTheme,
  t,
}: {
  currentEpisode?: Episode
  locale: string
  slug: string
  theaterDescription: string
  contentfulProjectTheme: Maybe<ProjectTheme>
  t: TranslateFunction
}) => {
  const ctaText = contentfulProjectTheme?.sneakPeekDuration
    ? t('watchFirstMinutes', `Watch The First {{ minutes }} Minutes`, {
        minutes: contentfulProjectTheme.sneakPeekDuration,
      })
    : t('watchSneakPeek', 'Watch Sneak Peek')
  return getTrailerCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
}

const getTrailerCtaProps = ({
  currentEpisode,
  locale,
  slug,
  theaterDescription,
  t,
}: {
  currentEpisode?: Episode
  locale: string
  slug: string
  theaterDescription: string
  t: TranslateFunction
}) => {
  const ctaText = t('watchTrailer', 'Watch Trailer')
  return getTrailerCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
}

const getDefaultTrailerCta = ({
  projectData,
  slug,
  theaterDescription,
  t,
}: {
  projectData: Project
  slug: string
  theaterDescription: string
  t: TranslateFunction
}) => {
  return {
    showDescription: theaterDescription,
    ctaPath: `/watch/${slug}/videos/trailers/${projectData?.trailers?.[0].id}`,
    ctaText: t('watchTrailer', 'Watch Trailer'),
  }
}

const getEarlyAccessCta = ({
  slug,
  theaterDescription,
  episodeTrailer,
  t,
}: {
  slug: string
  theaterDescription: string
  episodeTrailer: EpisodeLinkMeta
  t: TranslateFunction
}) => {
  return {
    showDescription: theaterDescription,
    ctaPath: `/watch/${slug}/episode/${episodeTrailer.id}`,
    ctaText: t('watchTrailer', 'Watch Trailer'),
  }
}

const getWatchNowCta = ({
  currentEpisode,
  locale,
  slug,
  theaterDescription,
  t,
}: {
  currentEpisode?: Episode
  locale: string
  slug: string
  theaterDescription: string
  t: TranslateFunction
}) => {
  const ctaText = t('watchNow', 'Watch Now')
  return getDefaultCta({ ctaText, currentEpisode, locale, slug, theaterDescription })
}

const getMovieEpisodeSharePath = ({
  projectType,
  projectData,
  slug,
  defaultCtaPath,
}: {
  projectType: ProjectType
  projectData?: Project
  slug: string
  defaultCtaPath: string
}): string => {
  if (projectType === 'movie') {
    const movieEpisode = projectData?.seasons?.[0]?.episodes?.find((episode) =>
      episode?.name?.toLowerCase().includes('movie'),
    )

    if (movieEpisode?.guid) {
      return `/watch/${slug}/episode/${movieEpisode.guid}`
    }
  }

  return defaultCtaPath
}
