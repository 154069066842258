import React from 'react'
import classNames from 'classnames'
import { Maybe } from 'graphql/jsutils/Maybe'
import { DateTime } from 'luxon'
import { Case, Default, Switch, When } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { GuildScore } from '@/atoms/GuildScore'
import { CircleCheckIcon } from '@/atoms/Icons/CircleCheckIcon'
import { GuildIconSingle } from '@/atoms/Icons/GuildIconSingle'
import { ClosedCaptionsBadge, ContentRatingBadge, HighDefinitionBadge } from '@/atoms/MetadataBadge'
import { CaptionMD, CaptionSM, CaptionXS, EyebrowSM, TitleXS } from '@/atoms/Text'
import { paths } from '@/constants'
import { projectTypes } from '@/constants/projectTypes'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { ProjectTheme } from '@/types/codegen-contentful'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import useWindowSize from '@/utils/useWindowSize'

const buttonClassNames = 'justify-center min-w-[121px] px-3 py-[11px] w-auto'

interface HeroProjectDescriptionNewProps {
  showDescription?: string
  showTitle: Maybe<string>
  showEarlyAccess: boolean | undefined
  slug: string
  handleLearnClicked: () => void
  contentfulProjectTheme: Maybe<ProjectTheme>
  guildScore?: number
}

export const HeroProjectDescriptionNew: React.FC<HeroProjectDescriptionNewProps> = ({
  showTitle,
  showEarlyAccess,
  slug,
  handleLearnClicked,
  contentfulProjectTheme,
  guildScore,
}) => {
  const { t } = useTranslate('watch')
  const { metadata, projectType, releaseDate, streamingAtLabel } = useProjectMeta()
  const { innerWidth } = useWindowSize()
  const releaseYear = releaseDate ? DateTime.fromISO(releaseDate).year : null
  const projectDescription = projectType === 'series' ? t('seriesDescription', 'series') : t('filmDescription', 'film')
  const watchHeroHeader = contentfulProjectTheme && contentfulProjectTheme?.watchHeroHeader

  return (
    <>
      <Switch>
        <Case condition={showEarlyAccess}>
          <TitleXS className="mb-4 flex flex-row items-center justify-start text-left" weight="bold">
            {streamingAtLabel}
          </TitleXS>
          <CaptionXS className="md:photon-caption-sm mb-4 text-balance text-left text-gray-500 md:max-w-[80%] md:text-gray-300">
            {t(
              'earlyAccessStreamProjectGuildv2',
              `Angel Guild members can stream {{ showTitle }} now! If you aren't a member of the Guild, you can easily join and watch the {{ projectDescription }} now!`,
              {
                showTitle: showTitle || '',
                projectDescription,
              },
            )}
          </CaptionXS>
        </Case>
        <Case condition={!showEarlyAccess && streamingAtLabel}>
          <CaptionMD className="mb-2 flex flex-row items-center justify-start text-left lg:mb-4" weight="bold">
            {streamingAtLabel}
          </CaptionMD>
        </Case>
      </Switch>

      <div className="mb-6 flex flex-col flex-wrap items-start justify-start gap-2 !text-core-gray-400 lg:mb-9 lg:flex-row lg:items-center">
        <div className="flex flex-row flex-wrap items-center justify-start gap-2">
          <When condition={guildScore}>
            <GuildScore guildScore={guildScore} />
          </When>
          <ContentRatingBadge contentRating={metadata?.contentRating} slug={slug} />
          <HighDefinitionBadge slug={slug} />
          <ClosedCaptionsBadge />
        </div>
        <div className="flex flex-row flex-wrap items-center gap-1 lg:gap-2">
          {releaseYear && (
            <>
              <CaptionSM className="sm:photon-caption-md">{releaseYear}</CaptionSM>
              <CaptionSM className="sm:photon-caption-md">•</CaptionSM>
            </>
          )}
          <CaptionSM className="sm:photon-caption-md whitespace-nowrap">
            <Switch>
              <Case condition={projectType === projectTypes.series}>
                <Translate i18nKey="tvSeries" t={t}>
                  TV Series
                </Translate>
              </Case>
              <Case condition={projectType === projectTypes.movie}>
                <Translate i18nKey="movie" t={t}>
                  Movie
                </Translate>
              </Case>
              <Case condition={projectType === projectTypes.podcast}>
                <Translate i18nKey="podcast" t={t}>
                  Podcast
                </Translate>
              </Case>
              <Case condition={projectType === projectTypes.special}>
                <Translate i18nKey="special" t={t}>
                  Special
                </Translate>
              </Case>
            </Switch>
          </CaptionSM>
          {metadata?.genres?.length && metadata.genres.length > 0 && (
            <CaptionSM className="sm:photon-caption-md">•</CaptionSM>
          )}
          {metadata?.genres?.map((genre: string, index) => {
            return (
              <div className="flex gap-1 lg:gap-2" key={genre}>
                <CaptionSM className="sm:photon-caption-md">{genre}</CaptionSM>
                <When condition={index < metadata.genres.length - 1}>
                  <CaptionSM className="sm:photon-caption-md">•</CaptionSM>
                </When>
              </div>
            )
          })}
        </div>
        <When condition={projectType === 'movie'}>
          <div
            className="h-6 max-h-6 rounded-2xl p-[2px]"
            style={{
              background:
                'linear-gradient(175deg, #EDD7C6 0%, #D6A177 13.88%, #FEDEC6 40.62%, #D48D59 62.21%, #A66141 88.77%, #612B12 98.15%)',
            }}
          >
            <div
              className="flex h-full w-full items-center justify-center gap-1 rounded-2xl px-3 pb-1 pt-[1px] md:px-2 lg:px-2"
              style={{
                background:
                  'linear-gradient(152deg, #F1DFD1 0%, #DFB695 13.88%, #F3D0B6 40.62%, #DEA67D 62.21%, #B98268 88.77%, #815642 98.15%)',
              }}
            >
              <Switch>
                <Case condition={innerWidth > 1024}>
                  <GuildIconSingle size={16} color="guild-copper" />
                </Case>
                <Case condition={innerWidth > 768}>
                  <GuildIconSingle size={14} color="guild-copper" />
                </Case>
                <Default>
                  <GuildIconSingle size={12} color="guild-copper" />
                </Default>
              </Switch>

              <CaptionSM
                className="-mb-[2px] whitespace-nowrap text-right text-guild-copper"
                weight="medium"
                color="guild-copper"
                style={{
                  backfaceVisibility: 'hidden',
                  willChange: 'transform',
                  WebkitFontSmoothing: 'subpixel-antialiased',
                }}
              >
                {t('guildMembers', 'Guild Members')}
              </CaptionSM>
            </div>
          </div>
        </When>
      </div>
      <div className="mb-6 flex flex-col gap-4 text-core-gray-400 se:gap-3">
        <EyebrowSM className="md:photon-eyebrow-md uppercase !text-white" weight="bold">
          <When condition={watchHeroHeader}>{watchHeroHeader}</When>
          <When condition={!watchHeroHeader}>{t('joinFullGuildBenefits', 'Join for full guild benefits')}</When>
        </EyebrowSM>
        <CaptionSM>
          <CircleCheckIcon className="mr-2 inline-block" size={16} color1="core-gray-500" color2="core-gray-950" />
          <Translate t={t} i18nKey="selectAngelNextBigShow">
            <span className="font-bold uppercase">Select </span>Angel&apos;s next big TV shows and movies.
          </Translate>
        </CaptionSM>
        <CaptionSM>
          <CircleCheckIcon className="mr-2 inline-block" size={16} color1="core-gray-500" color2="core-gray-950" />
          <Translate t={t} i18nKey="streamAllShowsHome">
            <span className="font-bold uppercase">Stream </span>all our shows from home before anyone else.
          </Translate>
        </CaptionSM>
        <CaptionSM className="hidden md:flex">
          <CircleCheckIcon className="mr-2 inline-block" size={16} color1="core-gray-500" color2="core-gray-950" />
          <Translate t={t} i18nKey="supportShowsMatter">
            <span className="mr-1 font-bold uppercase">Support </span>shows that matter, give feedback to filmmakers.
          </Translate>
        </CaptionSM>
        <CaptionSM className="md:hidden">
          <CircleCheckIcon className="mr-2 inline-block" size={16} color1="core-gray-500" color2="core-gray-950" />
          <Translate t={t} i18nKey="supportShowsMatterShort">
            <span className="font-bold uppercase">Support </span>shows that matter.
          </Translate>
        </CaptionSM>
        <CaptionSM>
          <CircleCheckIcon className="mr-2 inline-block" size={16} color1="core-gray-500" color2="core-gray-950" />
          <Translate t={t} i18nKey="saveOnMovieTickets">
            <span className="font-bold uppercase">Save </span>on movie tickets, merch, and more.
          </Translate>
        </CaptionSM>
      </div>
      <div className="-ml-3 mb-4 flex w-full items-start justify-start">
        <LinkButton
          href={paths.guild.join}
          variant="white"
          outline
          className={classNames(buttonClassNames, 'border-0 hover:!bg-transparent')}
          onClick={handleLearnClicked}
        >
          {t('learnMore', 'Learn More')}
        </LinkButton>
      </div>
    </>
  )
}
