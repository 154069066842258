import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Maybe } from 'graphql/jsutils/Maybe'
import { DateTime } from 'luxon'
import { Case, Else, If, Switch, Then, When } from 'react-if'
import { GuildScore } from '@/atoms/GuildScore'
import { ClosedCaptionsBadge, ContentRatingBadge, HighDefinitionBadge } from '@/atoms/MetadataBadge'
import { CaptionMD, CaptionSM, ParagraphLG, TitleXS } from '@/atoms/Text'
import { paths, slugs } from '@/constants'
import { projectTypes } from '@/constants/projectTypes'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface HeroProjectDescriptionProps {
  loading: boolean
  showDescription?: string
  showTitle?: Maybe<string>
  showEarlyAccess: boolean | undefined
  slug: string
  guildScore?: number
  hasWatchableReasons?: boolean
  isGuildMember?: boolean
}

const useTruncated = (loading: boolean) => {
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (!loading) {
        const element = document.getElementById('show-description')

        if (element) {
          setIsTruncated(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
        }
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [loading])

  return isTruncated
}

export const HeroProjectDescription: React.FC<HeroProjectDescriptionProps> = ({
  loading,
  showDescription,
  showTitle,
  showEarlyAccess,
  slug,
  guildScore,
  hasWatchableReasons,
  isGuildMember,
}) => {
  const { t } = useTranslate('watch')
  const [expanded, setExpanded] = useState(false)
  const isTruncated = useTruncated(loading)
  const { metadata, projectType, releaseDate, streamingAtLabel } = useProjectMeta()
  const releaseYear = releaseDate ? DateTime.fromISO(releaseDate).year : null
  const projectDescription = projectType === 'series' ? t('seriesDescription', 'series') : t('filmDescription', 'film')

  const handleReadMoreOrLess = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <div className="mb-8 flex flex-col items-center md:items-start">
        <ParagraphLG
          id="show-description"
          className={classNames('text-center md:text-left transition-[max-height] duration-[600ms] ease-in-out', {
            'max-h-[60px] line-clamp-2': !expanded,
            'overflow-hidden max-h-96': expanded,
          })}
          color="gray-100"
        >
          {showDescription}
        </ParagraphLG>
        <When condition={isTruncated}>
          <button onClick={handleReadMoreOrLess}>
            <ParagraphLG className="cursor-pointer underline" color="oxide">
              <If condition={expanded}>
                <Then>
                  <Translate i18nKey="readLess" t={t}>
                    read less
                  </Translate>
                </Then>
                <Else>
                  <Translate i18nKey="readMore" t={t}>
                    read more
                  </Translate>
                </Else>
              </If>
            </ParagraphLG>
          </button>
        </When>
      </div>
      <Switch>
        <Case condition={showEarlyAccess}>
          <TitleXS
            className="mb-4 flex flex-row items-center justify-center text-center md:justify-start md:text-left"
            weight="bold"
          >
            {streamingAtLabel}
          </TitleXS>
          <CaptionSM className="mb-4 text-center text-gray-500 md:max-w-[80%] md:text-left md:text-gray-300">
            {t(
              'earlyAccessStreamProjectGuildv1',
              `During our early access period, members of the Angel Guild can stream {{ showTitle }}. If you aren't a member of the Guild, you can easily join and watch the {{ projectDescription }} now!`,
              {
                showTitle: showTitle || '',
                projectDescription,
              },
            )}
          </CaptionSM>
        </Case>
        <Case condition={!showEarlyAccess && streamingAtLabel}>
          <TitleXS
            className="mb-4 flex flex-row items-center justify-center text-center md:justify-start md:text-left"
            weight="bold"
          >
            {streamingAtLabel}
          </TitleXS>
        </Case>
      </Switch>

      <div className="mb-9 flex flex-row flex-wrap justify-center gap-2 text-gray-500 md:justify-start md:text-gray-300">
        {releaseYear && (
          <>
            <CaptionMD>{releaseYear}</CaptionMD>
            <CaptionMD>•</CaptionMD>
          </>
        )}
        <CaptionMD className="whitespace-nowrap">
          <Switch>
            <Case condition={projectType === projectTypes.series}>
              <Translate i18nKey="tvSeries" t={t}>
                TV Series
              </Translate>
            </Case>
            <Case condition={projectType === projectTypes.movie}>
              <Translate i18nKey="movie" t={t}>
                Movie
              </Translate>
            </Case>
            <Case condition={projectType === projectTypes.podcast}>
              <Translate i18nKey="podcast" t={t}>
                Podcast
              </Translate>
            </Case>
            <Case condition={projectType === projectTypes.special}>
              <Translate i18nKey="special" t={t}>
                Special
              </Translate>
            </Case>
          </Switch>
        </CaptionMD>
        {metadata?.genres?.length && metadata.genres.length > 0 && <CaptionMD>•</CaptionMD>}
        {metadata?.genres?.map((genre: string, index) => {
          return (
            <div className="flex gap-2" key={genre}>
              <CaptionMD>{genre}</CaptionMD>
              <When condition={index < metadata.genres.length - 1}>
                <CaptionMD>•</CaptionMD>
              </When>
            </div>
          )
        })}
        <div className="flex flex-row items-center gap-2 md:basis-full">
          <When condition={guildScore}>
            <GuildScore guildScore={guildScore} />
          </When>
          <ContentRatingBadge contentRating={metadata?.contentRating} slug={slug} />
          <HighDefinitionBadge slug={slug} />
          <ClosedCaptionsBadge />
        </div>
      </div>
      {slug === slugs.homesteadSeries && !hasWatchableReasons && !isGuildMember && (
        <div className="text-center md:text-left">
          <CaptionSM color="core-gray-300" className="md:photon-caption-md">
            <Translate i18nKey="missedPayItForward" t={t}>
              Missed paying it forward in the theater?
            </Translate>
          </CaptionSM>
          <CaptionSM color="core-gray-300" className="md:photon-caption-md mb-4">
            <Translate i18nKey="payItForwardHomestead" t={t}>
              Pay it Forward{' '}
              <a
                href={`${paths.payItForward.index}/${slugs.homestead}`}
                className="cursor-pointer text-oxide underline"
              >
                here
              </a>{' '}
              to get access to Episode 1 of Homestead.
            </Translate>
          </CaptionSM>
        </div>
      )}
    </>
  )
}
