import React from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { LinkButton } from '@/atoms/Button'
import { GiftIcon } from '@/atoms/Icons/GiftIcon'
import { paths } from '@/constants/paths'
import { buildAnalyticsLink } from '@/utils/AnalyticsLinkBuilder'

interface MerchButtonProps {
  projectSlug: string
  className?: string
  text?: string
  onClick?: () => void
  target?: '_blank'
}

const PROJECT_SLUG_TO_STORE_SLUG: Record<string, string> = {
  'tuttle-twins': 'show-tuttle-twins',
  'wingfeather-saga': 'show-the-wingfeather-saga',
}

export const MerchButton: React.FC<MerchButtonProps> = ({ className, text, projectSlug, onClick, target }) => {
  const { asPath } = useRouter()

  const shopSlug = PROJECT_SLUG_TO_STORE_SLUG[projectSlug]
  const href = shopSlug ? `${paths.external.shop.index}pages/${shopSlug}` : `${paths.external.shop.index}`

  return (
    <LinkButton
      className={classNames('py-3 px-6 md:w-fit fill-white hover:fill-black backdrop-blur-md', className)}
      href={buildAnalyticsLink({ href, path: asPath, campaign: 'project-merch-cta', linkContext: 'watch-tab' })}
      onClick={onClick}
      variant="white"
      target={target}
      prefetch
      outline
    >
      <div className="relative flex w-full flex-row items-center justify-center">
        <GiftIcon size={24} color="current" className="fill-inherit" />
        <div className="ml-2">{text}</div>
      </div>
    </LinkButton>
  )
}
