import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Maybe } from 'graphql/jsutils/Maybe'
import { Case, Default, Switch, When } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { Image } from '@/atoms/Image'
import { CaptionSM } from '@/atoms/Text'
import { slugs } from '@/constants'
import { useExperimentContext } from '@/experimentation'
import { JoinGuildButton } from '@/molecules/JoinGuildButton'
import { LivestreamThumbnail } from '@/molecules/LivestreamThumbnail'
import { MerchButton } from '@/molecules/MerchButton'
import { GiftGuildButton } from '@/molecules/PayItForwardButton/GuildGiftButton'
import { GuildEarlyAccessModal } from '@/organisms/GuildEarlyAccessModal/GuildEarlyAccessModal'
import { ShopifyStoreConfig } from '@/services/EarlyAccessService'
import { Promotion } from '@/services/GuildPromotionService'
import { Episode, ProjectType } from '@/services/ProjectsService'
import { getCurrentLivestreamQuery } from '@/services/ProjectsService/queries'
import { GetTheatricalPreReleasePageDataResult } from '@/services/TheatricalPreReleaseService'
import { TheatricalRegion } from '@/types/codegen-contentful'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import { GuildContentLinkingBanner } from '../CollectiblesPlayer/GuildContentLinking'
import { GuildJoinTheAdventureBanner } from '../GuildJoinTheAdventureBanner'
import { HeroHeader } from './HeroHeader'
import { HeroProjectDescription } from './HeroProjectDescription'
import { ResponsiveBackgroundContainer } from './ResponsiveBackgroundContainer'
import { WatchHeroCTA } from './WatchHeroCTA'
import { WatchMerchCard } from './WatchMerchCard'
import { WatchProgressStatus } from './WatchProgressStatus'
import { JOIN_THE_ADVENTURE_PROJECTS } from './constants'

export type BackgroundPosition =
  | 'bg-top'
  | 'bg-center'
  | 'bg-bottom'
  | 'bg-left'
  | 'bg-left-bottom'
  | 'bg-left-top'
  | 'bg-right'
  | 'bg-right-bottom'
  | 'bg-right-top'

export interface WatchProjectHeroProps {
  className?: string
  cloudinaryImgPath: string
  ctaClassName?: string
  ctaPath?: string
  ctaText?: string
  descriptionTitle?: string
  duration?: number
  episodeTitle?: string
  id: string
  imageClassName?: string
  imageContainerClassName?: string
  position?: number
  slug: string
  showDescription?: string
  showTitle?: Maybe<string>
  theaterBackgroundPosition?: BackgroundPosition
  theatricalRegion?: TheatricalRegion[]
  theatricalRelease?: TheatricalReleaseObject
  isGuildMemberContent?: boolean
  handlePageLoad?: () => void
  openGuildEarlyAccessModal?: () => void
  currentEpisode: Episode | undefined
  promotion: Promotion | null
  projectType: ProjectType
  pageData?: GetTheatricalPreReleasePageDataResult
  shopifyConfig?: ShopifyStoreConfig
  guildScore?: number
  isGuildMember: boolean
  isLoggedIn: boolean
  hasWatchableReasons?: boolean
  isGeoBlockedProject?: boolean
  shareCtaPath?: string
}

export const WatchProjectHero: React.FC<WatchProjectHeroProps> = (props) => {
  const {
    className,
    cloudinaryImgPath,
    ctaClassName,
    ctaPath,
    ctaText,
    descriptionTitle,
    duration,
    id,
    position,
    showDescription,
    showTitle,
    slug,
    theaterBackgroundPosition,
    isGuildMemberContent,
    currentEpisode,
    promotion,
    theatricalRelease,
    projectType,
    pageData,
    shopifyConfig,
    guildScore,
    isGuildMember,
    isLoggedIn,
    hasWatchableReasons = false,
    isGeoBlockedProject,
    shareCtaPath,
  } = props

  const { t } = useTranslate('watch')
  const track = useSafeTrack()
  const { getFeatureValue } = useExperimentContext()
  const showJoinTheAdventureBanner =
    !isGuildMember &&
    JOIN_THE_ADVENTURE_PROJECTS.includes(slug) &&
    getFeatureValue('guild_join_the_adventure_banner', false)
  const projectShareAvailable = projectType === 'movie' && isGuildMemberContent && isGuildMember
  const showShareBanner = projectShareAvailable && isGuildMember

  const { data: currentLivestreamData, loading: loadingLivestream } = useQuery(getCurrentLivestreamQuery, {
    variables: { projectId: id },
    errorPolicy: 'all',
  })

  const showDefaultMerch = [slugs.tuttleTwins, slugs.wingfeatherSaga].includes(slug)
  const ticketsStatus = theatricalRelease?.region?.ticketsStatus
  const canShowTickets = Boolean(ticketsStatus && (ticketsStatus === 'PRE_SALES' || ticketsStatus === 'LIVE'))

  const trackExperiment = (projectSlug: string) => {
    track('Show Page CTA Clicked', { slug: projectSlug })
  }

  const handleGuildClick = useCallback(() => {
    track('Guild Watch Join Clicked')
  }, [track])

  const handleShareClick = useCallback(() => {
    track('Content Link Project Share Film Clicked')
  }, [track])

  const merchAnalytics = useMemo(() => {
    return { context: 'watch-project', program: slug }
  }, [slug])

  const showExperiment = (isLoggedIn && !isGuildMember) || !isLoggedIn

  const canShowStats = Boolean(
    !shopifyConfig && pageData?.filmDetails?.statsBox && !currentLivestreamData?.currentLivestream,
  )
  const canShowMerch = Boolean(shopifyConfig && !currentLivestreamData?.currentLivestream)

  return (
    <>
      <div className={classNames(['flex flex-col gap-4 relative w-full md:flex-row md:flex-wrap', className])}>
        <ResponsiveBackgroundContainer
          cloudinaryImgPath={cloudinaryImgPath}
          theaterBackgroundPosition={theaterBackgroundPosition}
        />
        <div className="relative mt-40 w-full md:max-w-[650px]">
          <HeroHeader loading={false} showTitle={showTitle} descriptionTitle={descriptionTitle} />
          <HeroProjectDescription
            loading={false}
            showTitle={showTitle}
            showDescription={showDescription}
            showEarlyAccess={isGuildMemberContent}
            slug={slug}
            guildScore={guildScore}
            hasWatchableReasons={hasWatchableReasons}
            isGuildMember={isGuildMember}
          />
          <WatchProgressStatus position={position} duration={duration} />
          <div>
            <div className="flex flex-col md:flex-row md:flex-wrap">
              <GuildEarlyAccessModal episode={currentEpisode} projectSlug={slug} promotion={promotion}>
                <WatchHeroCTA
                  ctaPath={ctaPath}
                  ctaText={ctaText}
                  ctaClassName={ctaClassName}
                  isGuildMemberContent={isGuildMemberContent}
                  isGeoBlockedProject={isGeoBlockedProject}
                />
              </GuildEarlyAccessModal>
              {!isGuildMember && !showDefaultMerch && !canShowTickets && !showJoinTheAdventureBanner && (
                <JoinGuildButton
                  onClick={handleGuildClick}
                  text={t('joinTheGuild', 'Join The Guild')}
                  projectSlug={slug}
                  href={`/guild/join/${slug}`}
                  className={ctaClassName}
                  target={'_self'}
                />
              )}
              {!showDefaultMerch && !canShowTickets && isGuildMember && <GiftGuildButton />}
              {canShowTickets && (
                <LinkButton
                  className={classNames('mt-4 md:mt-0 py-3 w-full md:w-fit', ctaClassName)}
                  href={slug ? `/tickets/${slug}` : '/tickets'}
                  variant="white"
                  outline
                >
                  <span className="mr-2">
                    <TicketIcon color="white" />
                  </span>
                  {t('getShowtimes', 'Get Showtimes')}
                </LinkButton>
              )}
              {showDefaultMerch && (
                <Switch>
                  <Case condition={slug === slugs.wingfeatherSaga && showExperiment}>
                    <JoinGuildButton
                      onClick={() => trackExperiment(slug)}
                      text={t('joinTheGuild', 'Join The Guild')}
                      projectSlug={slug}
                      href={'/guild/join/wingfeather-saga'}
                      className={ctaClassName}
                      target={'_self'}
                    />
                  </Case>
                  <Default>
                    <MerchButton
                      onClick={() => trackExperiment(slug)}
                      text={t('shopGifts', 'Shop Gifts')}
                      projectSlug={slug}
                      className={ctaClassName}
                    />
                  </Default>
                </Switch>
              )}
              <When condition={currentLivestreamData?.currentLivestream && !showDefaultMerch}>
                <MerchButton
                  onClick={() => trackExperiment(slug)}
                  text={t('shopGifts', 'Shop Gifts')}
                  projectSlug={slug}
                  className={classNames('mt-4 md:mt-0 py-3 w-full md:w-fit md:ml-4', ctaClassName)}
                />
              </When>
            </div>
          </div>
        </div>

        {showJoinTheAdventureBanner && (
          <GuildJoinTheAdventureBanner
            className="lg:order-1"
            linkContext="watch-project-hero"
            onClick={handleGuildClick}
            slug={slug}
          />
        )}

        {showShareBanner && (
          <GuildContentLinkingBanner className="lg:order-1" onClick={handleShareClick} ctaPath={shareCtaPath} />
        )}

        <div
          className="relative mx-auto mt-4 flex items-center sm:mt-0 md:mx-0 md:ml-auto md:items-end"
          id="current-livestream"
        >
          <When condition={currentLivestreamData?.currentLivestream}>
            <When condition={!loadingLivestream}>
              <Transition
                show
                appear
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <LivestreamThumbnail
                  {...currentLivestreamData?.currentLivestream}
                  className="h-[180px] w-[320px]"
                  animationClassName="ease-in-out duration-[1000ms] md:hover:scale-[1.02]"
                />
              </Transition>
            </When>
          </When>
          <When condition={canShowStats}>
            <div className="flex min-h-[80px] w-[200px] flex-col items-center justify-start gap-2 rounded-2xl bg-core-gray-950/70 p-4 lg:min-h-[86px]">
              {pageData?.filmDetails?.statsBox?.logoImageUrl && (
                <div className="flex max-h-6 max-w-full items-center justify-center lg:max-h-[30px] lg:min-h-[30px]">
                  <Image
                    alt={pageData?.filmDetails?.statsBox?.statsDescription ?? ''}
                    height={24}
                    width={150}
                    className="mx-auto max-h-6 w-auto max-w-[150px] lg:max-h-[30px]"
                    src={getCloudinaryImageUrl({
                      path: pageData?.filmDetails?.statsBox?.logoImageUrl as string,
                      transforms: 'e_trim',
                    })}
                  />
                </div>
              )}
              <CaptionSM className="text-pretty" color="core-gray-300">
                <When condition={isGuildMember}>
                  {pageData?.filmDetails?.statsBox?.guildStatsDescription ??
                    pageData?.filmDetails?.statsBox?.statsDescription}
                </When>
                <When condition={!isGuildMember}>{pageData?.filmDetails?.statsBox?.statsDescription}</When>
              </CaptionSM>
            </div>
          </When>
          <When condition={canShowMerch}>
            {shopifyConfig && (
              <Transition
                show
                appear
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <WatchMerchCard
                  storeUrl={shopifyConfig.storeUrl}
                  accessToken={shopifyConfig.accessToken}
                  handle={shopifyConfig.handle}
                  storeHref={shopifyConfig?.storeHref}
                  analytics={merchAnalytics}
                  slug={slug}
                  isGuildMember={isGuildMember}
                />
              </Transition>
            )}
          </When>
        </div>
      </div>
    </>
  )
}
