import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { LinkButton } from '@/atoms/Button'
import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { AsLabel, LabelSM, TextProps } from '@/atoms/Text'
import { NamedColor } from '@/constants/types'
import { useGuildMember } from '@/services/GuildUserService'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'

export interface GuildCTAButtonProps {
  className?: string
  textLabelColor?: NamedColor
  iconColor?: NamedColor
  hideIcon?: boolean
  guildButtonText?: string
  buttonColorClassNames?: string
  onClick?: () => void | null
  textClassName?: string
  href?: string
  labelWeight?: TextProps['weight']
  style?: React.CSSProperties
}

export const GuildCTAButtonWrapper: React.FC<GuildCTAButtonProps> = (props) => {
  const { guildCtaProps } = useGuildSignupContext()

  return <GuildCTAButton {...guildCtaProps} {...props} />
}

const GuildCTAButton: React.FC<GuildCTAButtonProps> = ({
  className,
  iconColor = 'white',
  textLabelColor = 'white',
  hideIcon = false,
  guildButtonText,
  onClick,
  textClassName = 'photon-label-lg',
  labelWeight = 'medium',
  href,
  buttonColorClassNames,
  style,
}) => {
  const [isMounted, setMounted] = useState(false)
  const { isGuildMember, loading } = useGuildMember()
  const { t } = useTranslate('guild')
  const { emphasis, guildMemberHref, guildMemberCta } = useGuildSignupContext()

  useEffect(() => {
    setMounted(true)
  }, [])

  let icon = <GuildIcon color1={iconColor} size={24} />

  if (emphasis === 'tickets') {
    icon = <TicketIcon color={iconColor} size={24} />
  }

  const isLoading = !isMounted || loading

  return (
    <>
      {isLoading && <div className="h-[58px] w-[354px]" />}
      {!isLoading && (
        <Transition
          show
          appear
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <LinkButton
            className={classNames(
              'mb-3 w-full max-w-[342px] whitespace-nowrap rounded-[14px] py-3.5 px-8',
              buttonColorClassNames,
              className,
            )}
            variant="oxide"
            href={href ? href : isGuildMember && guildMemberHref ? guildMemberHref : '#plan-selection'}
            onClick={onClick}
            style={style}
          >
            <div className="flex min-h-6 flex-row items-center">
              {!hideIcon && <span className="mr-2">{icon}</span>}
              <LabelSM
                as={AsLabel}
                weight={labelWeight}
                color={textLabelColor}
                className={classNames('cursor-pointer', textClassName)}
              >
                {isGuildMember
                  ? guildMemberCta
                  : guildButtonText ?? (
                      <Translate i18nKey="joinTheGuild" t={t}>
                        Join the Guild
                      </Translate>
                    )}
              </LabelSM>
            </div>
          </LinkButton>
        </Transition>
      )}
    </>
  )
}
