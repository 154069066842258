import React from 'react'
import classNames from 'classnames'
import { Maybe } from 'graphql/jsutils/Maybe'
import { When } from 'react-if'
import { AsH1, AsH2, HeadingLG, HeadingXS } from '@/atoms/Text'

interface HeroHeaderProps {
  loading: boolean
  showTitle: Maybe<string>
  descriptionTitle?: Maybe<string>
  className?: string
}

export const HeroHeader: React.FC<HeroHeaderProps> = ({ loading, showTitle, descriptionTitle, className }) => {
  return (
    <header>
      {!!showTitle && (
        <HeadingLG
          as={AsH1}
          className={classNames('mb-4 text-center md:text-left text-wrap max-w-[500px]', className)}
          weight="bold"
        >
          {showTitle}
        </HeadingLG>
      )}

      <When condition={!loading && descriptionTitle && showTitle !== descriptionTitle}>
        <HeadingXS as={AsH2} className="mb-2 text-center md:text-left" weight="bold">
          {descriptionTitle}
        </HeadingXS>
      </When>
    </header>
  )
}
