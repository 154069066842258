import React, { useMemo } from 'react'
import { TextProps } from '@/atoms/Text'
import { paths } from '@/constants'
import { ANGEL_GUILD_PREMIUM_MONTHLY_OFFER_ID, ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID } from '@/constants/offer-ids'
import { AppFeatures } from '@/experimentation'
import { CatalogTitle } from '@/services/ContentCatalog'
import { Promotion } from '@/services/GuildPromotionService'
import { GetGuildTheatricalReleasesResult } from '@/services/GuildReleasesService/TheatricalReleaseService'
import { GuildEarlyAccessSectionModel, GuildProjectSlugs, GuildSignupEmphasis } from '@/services/GuildSignupPageService'
import { GuildSignupLayout } from '@/services/GuildSignupPageService/GuildSignupPageTypes'
import { isGuildSignupSimpleLayout } from '@/services/GuildSignupPageService/validations'
import { GuildSale } from '@/services/SaleService'
import { Collection, Faq, GuildSignupExplainerLayout, GuildTestimonialSection } from '@/types/codegen-contentful'
import { ReactFCC } from '@/types/react'
import { GuildMovieTicketRelease } from '@/views/GuildDashboardView/GuildMovieTicketsPanel/types'
import { GuildCTAButtonProps } from '@/views/GuildSignupView/GuildCTAButton/GuildCTAButton'

interface GuildSignupContextProviderValues {
  catalogTitle?: CatalogTitle
  emphasis?: GuildSignupEmphasis
  guildApprovedTorchPosters?: string[]
  guildExplainerLayout?: GuildSignupExplainerLayout | null
  guildFaqs?: Faq[]
  guildMemberCta?: string
  guildMemberHref?: string
  layout?: GuildSignupLayout
  monthlyPlan: GuildPlan
  projectSlugs?: GuildProjectSlugs
  promotion: Promotion | null
  sale: GuildSale | null
  slug?: string
  theatricalReleases?: GetGuildTheatricalReleasesResult
  yearlyPlan: GuildPlan
  votingPhoneImageUrl?: string | null
  guildTestimonialSection?: GuildTestimonialSection
  guildEarlyAccessSection?: GuildEarlyAccessSectionModel
  movieTicketReleases?: GuildMovieTicketRelease[]
  guildPredictsWinners?: Collection
  guildCtaProps: GuildCTAButtonProps
  logo?: {
    src: string
    alt: string
    className?: string
  }
  experiments: Record<keyof AppFeatures, unknown>
  isHideAppPromo?: boolean
  isHideJoinTheMovement?: boolean
  members?: number
  lastUpdated?: string
  shouldShowAvailabilityWarning?: boolean
}

interface GuildPlan {
  offerId: string
  price: number
  currency: string
}

const GuildSignupContext = React.createContext<GuildSignupContextProviderValues>({} as GuildSignupContextProviderValues)

export interface GuildSignupContextProviderProps {
  catalogTitle?: CatalogTitle
  emphasis?: GuildSignupEmphasis
  guildApprovedTorchPosters?: string[]
  guildExplainerLayout?: GuildSignupExplainerLayout | null
  guildFaqs?: Faq[]
  guildMemberCta?: string
  guildMemberHref?: string
  layout?: GuildSignupLayout
  projectSlugs?: GuildProjectSlugs
  promotion?: Promotion | null
  sale?: GuildSale | null
  slug?: string
  theatricalReleases?: GetGuildTheatricalReleasesResult
  votingPhoneImageUrl?: string | null
  guildTestimonialSection?: GuildTestimonialSection
  guildEarlyAccessSection?: GuildEarlyAccessSectionModel
  movieTicketReleases?: GuildMovieTicketRelease[]
  guildPredictsWinners?: Collection
  canShowAuth?: boolean
  logo?: {
    src: string
    alt: string
    className?: string
  }
  experiments?: Record<string, unknown>
  isHideAppPromo?: boolean
  isHideJoinTheMovement?: boolean
  members?: number
  lastUpdated?: string
  shouldShowAvailabilityWarning?: boolean
}

const DEFAULT_MONTHLY_PLAN = {
  price: 2000,
  currency: 'USD',
  offerId: ANGEL_GUILD_PREMIUM_MONTHLY_OFFER_ID,
} as GuildPlan

const DEFAULT_YEARLY_PLAN = {
  price: 17900,
  currency: 'USD',
  offerId: ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID,
} as GuildPlan

export const GuildSignupContextProvider: ReactFCC<GuildSignupContextProviderProps> = ({
  catalogTitle,
  children,
  emphasis = 'early-access',
  guildApprovedTorchPosters = [],
  guildExplainerLayout,
  guildFaqs = [],
  guildMemberCta = 'Guild Member Benefits',
  guildMemberHref = paths.account.guild,
  layout,
  projectSlugs,
  promotion = null,
  sale = null,
  slug,
  votingPhoneImageUrl,
  guildTestimonialSection,
  movieTicketReleases,
  guildPredictsWinners,
  logo,
  experiments = {},
  isHideAppPromo,
  isHideJoinTheMovement,
  members,
  lastUpdated,
  shouldShowAvailabilityWarning,
}) => {
  const guildCtaProps = useMemo(() => {
    return isGuildSignupSimpleLayout(layout)
      ? {
          hideIcon: true,
          buttonColorClassNames: '!w-fit !border-warm-03 !bg-warm-03',
          labelWeight: 'bold' as TextProps['weight'],
        }
      : ({} as Record<string, unknown>)
  }, [layout])

  const value = useMemo(() => {
    return {
      catalogTitle,
      emphasis,
      guildApprovedTorchPosters,
      guildExplainerLayout,
      guildFaqs,
      guildMemberCta,
      guildMemberHref,
      layout,
      monthlyPlan: DEFAULT_MONTHLY_PLAN,
      projectSlugs,
      promotion,
      sale,
      slug,
      yearlyPlan: DEFAULT_YEARLY_PLAN,
      votingPhoneImageUrl,
      guildTestimonialSection,
      movieTicketReleases,
      guildPredictsWinners,
      guildCtaProps,
      logo,
      experiments,
      isHideAppPromo,
      isHideJoinTheMovement,
      members,
      lastUpdated,
      shouldShowAvailabilityWarning,
    }
  }, [
    catalogTitle,
    emphasis,
    guildApprovedTorchPosters,
    guildExplainerLayout,
    guildFaqs,
    guildMemberCta,
    guildMemberHref,
    layout,
    projectSlugs,
    promotion,
    sale,
    slug,
    votingPhoneImageUrl,
    guildTestimonialSection,
    movieTicketReleases,
    guildPredictsWinners,
    guildCtaProps,
    logo,
    experiments,
    isHideAppPromo,
    isHideJoinTheMovement,
    members,
    lastUpdated,
    shouldShowAvailabilityWarning,
  ])

  return <GuildSignupContext.Provider value={value}>{children}</GuildSignupContext.Provider>
}

export const useGuildSignupContext = () => {
  return React.useContext(GuildSignupContext)
}

// TODO: this could come from a backend someday, but right now the backend is blocked by Auth
export async function getGuildApprovedTorchPosters(): Promise<string[]> {
  return Promise.resolve([
    'v1652137331/angel-app/for-your-consideration/TGK_Short_Poster_02',
    'v1666297265/angel-app/guild/torches/Tempest_poster',
    //CABRINI
    'v1722893901/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_7',
    //SIGHT
    'v1722892302/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes',
    //SOUND OF HOPE
    'v1722892372/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_1',
    //YOUNG DAVID
    'v1698961310/studio-app/catalog/ce1a0a75-cea7-48fa-96e4-e1fedeb27c69',
    //HOS
    'v1722893790/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_6',
    //KING OF KINGS
    'v1733954073/studio-app/catalog/60055f71-5914-4bc4-a565-1b7ecf9b0529',
    //THE SHIFT
    'v1722893972/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_8',
    //BRAVE THE DARK
    'v1736283404/studio-app/catalog/2ccf9b65-8999-4988-b1f8-78e0ee98e8ae',
    //SOF
    'v1722892415/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_2',
    //THE BLIND
    'v1732053127/studio-app/catalog/d6951b33-8928-4429-a3a3-48a4a9816e8f',
    //THE WAY
    'v1730496530/studio-app/catalog/dfaef667-8716-438d-b5b4-c5e0a44ab0f0',
    //REDEMPTION OF HENRY MYERS
    'v1731954322/studio-app/catalog/d7c6f59e-b24c-47cb-8a39-b80d58179c07',
    //A WEEK AWAY
    'v1726254061/studio-app/catalog/41dbc9f0-d1cb-4454-bb16-e360c1d98923',
    //RIOT SERIES
    'v1731957754/studio-app/catalog/bde03317-a5b9-4ab1-a9b8-43b35bb04516',
    //AXIOM
    'v1726683468/studio-app/catalog/3fae78ea-d597-4ebf-b6dd-3b17d1b14414',
    //LOST ON A MOUNTAIN IN MAINE
    'v1729891691/studio-app/catalog/4a32d1c9-b40e-4dfc-8a1b-e7b38bc236e2',
    //BONHOEFFER
    'v1728331907/studio-app/catalog/17caced6-9c51-4b55-ba10-b30df7c51cc7',
    //FAITH OF ANGELS
    'v1739307746/studio-app/catalog/9fb873c5-7b65-4f41-a90b-2c91da6de1d4',
    //BETWEEN BORDERS
    'v1738778720/studio-app/catalog/a4753d0a-2423-4458-8215-74babfff1bfc',
    //RULE BREAKERS
    'v1737157325/studio-app/catalog/ce306a02-f24b-4478-8aee-d504b7750af5',
    //SOMETHING TO STAND FOR
    'v1728709878/studio-app/catalog/7899a3b5-e479-4f6c-b9fd-348bb2f1d73e',
    'v1652468593/angel-app/for-your-consideration/David_16x9_KeyArt_en',
    // DRY BAR
    'v1680734237/angel-studios/blog/DBC-Splash2_Landscape-2732x2048',
    //HOMESTEAD
    'v1730220298/studio-app/catalog/4297c379-a0bc-47b3-ac0e-8420ec876963',
    'v1695657198/angel-app/guild/torches/after_death_thumbnail',
    'v1652909278/angel-app/for-your-consideration/Testament_Poster_Horiz_16x9',
    //THE LAST RIFLEMAN
    'v1738354473/studio-app/catalog/cc1868c1-8341-4396-8c6d-f104b5430a65',
    'v1722898802/angel-studios/guild/photon/gatg_new_image_cleanup',
    //WFS
    'v1722892617/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_5',
    //TT
    'v1722892534/angel-studios/guild/photon/Orientation_Landscape_Emphasis_Primary_Key_Art_Yes_3',
  ])
}
